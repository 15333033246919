<template>
  <!-- 模拟面试进度弹窗 -->
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
    width="600px"
  >
    <div class="container">
      <template v-if="data.status && data.status.value == 2 && !confirmedTime">
        <div class="title">确认时段</div>
        <div class="description">
          面试官已经确认5个大时间段
          <br />
          请选择一下任意一个时间段并填写开始面试时间
        </div>
        <div class="timeRange">
          <el-select v-model="timeZone" placeholder="请选择时区">
            <el-option label="美东时间" :value="0"></el-option>
            <el-option label="美中时间" :value="1"></el-option>
            <el-option label="美西时间" :value="2"></el-option>
            <el-option label="北京时间" :value="3"></el-option>
          </el-select>

          <div
            class="pickerRange"
            v-for="(picker, index) in timeRange"
            :key="index"
          >
            <el-date-picker
              style="width: 140px; margin-right: 12px"
              v-model="timeRange[index].date"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择日期"
            >
            </el-date-picker>
            <el-time-picker
              is-range
              v-model="timeRange[index].timeRange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              value-format="HH:mm:ss"
            >
            </el-time-picker>
          </div>
        </div>
      </template>
      <template v-if="(data.status && data.status.value == 3) || confirmedTime">
        <div class="title">等待面试者确认时间</div>
        <div class="description">面试者正在确认面试时间，请耐心等待</div>
      </template>

      <template v-if="data.status && data.status.value == 4 && !endInterview">
        <div class="title">待面试</div>
        <div class="description">
          面试时间为：{{ mockInfo.timeZoneType && mockInfo.timeZoneType.name }}
          {{ mockInfo.interviewTime }}，请准时使用Skype参加面试
          <br />
          若面试有所变动，请联系客服人员
          <br />
          <img class="qrcode" :src="qrcode && qrcode.imageUrl" alt="" />
          <div class="description" style="padding: 20px 0">
            若面试结束，请上传面试结果（PDF）
          </div>
          <el-button size="small" type="primary" @click="endInterviewEvent"
            >面试结束</el-button
          >
        </div>
      </template>
      <template v-if="(data.status && data.status.value == 6) || endInterview">
        <div class="title">请上传面试结果</div>
        <div class="description">
          面试结果以PDF形式上传
          <br />
          内容根据面试内容不同，包含代码，评价等
        </div>
        <div class="comment-container">
          <el-upload
            :action="uploadUrl"
            ref="upload"
            show-file-list
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
            :limit="1"
            accept=".pdf,.PDF"
            :data="{}"
            :headers="{
              'X-Access-Token': token,
              filePath: '/interview/review',
            }"
            :on-remove="removeFile"
          >
            <el-button size="small" icon="el-icon-document">上传文件</el-button>
          </el-upload>
        </div>
      </template>
    </div>
    <div slot="footer" style="text-align: center">
      <template>
        <el-button
          v-if="(data.status && data.status.value != 4) || endInterview"
          type="primary"
          size="small"
          @click="confirmEvent"
          >确定</el-button
        >
        <el-button
          v-if="data.status && data.status.value == 2 && !confirmedTime"
          type="default"
          size="small"
          @click="dialogVisible = false"
          >取消</el-button
        >
      </template>
    </div>
  </el-dialog>
</template>
<script>
import { getCourseBanner } from "@/service";
import {
  getMockStatus,
  confirmInterviewTimeRange,
  saveInterviewResults,
  endInterview,
} from "@/service/mock";
export default {
  name: "mockInterviewProcedure",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
  },
  data() {
    const that = this;
    return {
      timeZone: "",
      qrcode: {},
      mockInfo: {},
      timeRange: [
        {
          date: "",
          timeRange: ["", ""],
        },
        {
          date: "",
          timeRange: ["", ""],
        },
        {
          date: "",
          timeRange: ["", ""],
        },
        {
          date: "",
          timeRange: ["", ""],
        },
        {
          date: "",
          timeRange: ["", ""],
        },
      ],
      confirmedTime: false,
      token: window.localStorage.getItem("CSON_PAGE_TOKEN"),
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/upload",
      result: "",
      endInterview: false,
    };
  },
  methods: {
    confirmEvent() {
      if (
        this.data.status &&
        this.data.status.value == 2 &&
        !this.confirmedTime
      ) {
        if (this.timeZone === "") {
          return this.$message.warning("请选择时区！");
        }
        if (
          this.timeRange.filter((item) => {
            return item.timeRange.length > 0 && item.date != "";
          }).length < 3
        ) {
          return this.$message.warning("最少选择3个时段！");
        }
        //validate
        for (let i = 0; i < this.timeRange.length; i++) {
          if (
            (this.timeRange[i].date != "" &&
              this.timeRange[i].timeRange[0] == "" &&
              this.timeRange[i].timeRange[1] == "") ||
            (this.timeRange[i].date == "" &&
              this.timeRange[i].timeRange[0] != "" &&
              this.timeRange[i].timeRange[1] != "")
          ) {
            console.log(this.timeRange[i]);
            return this.$message.warning("请填写完整时段信息！");
          }
        }
        const data = {
          id: this.data.id,
          timeZoneType: this.timeZone,
          selectInterviewTime: this.timeRange
            .filter((item) => {
              return (
                item.timeRange.length > 0 &&
                item.timeRange[0] != "" &&
                item.timeRange[1] != "" &&
                item.date != ""
              );
            })
            .map((item) => {
              return {
                date: item.date,
                startTime: item.timeRange[0],
                endTime: item.timeRange[1],
              };
            }),
        };
        confirmInterviewTimeRange(data).then((res) => {
          if (res.success) {
            this.confirmedTime = true;
            this.$emit("reload");
          }
        });
        console.log(this.timeRange);
      } else if (
        (this.data.status && this.data.status.value == 6) ||
        this.endInterview
      ) {
        if (this.result == "") {
          return this.$message.warning("请上传面试结果！");
        }
        const data = {
          id: this.data.id,
          result: this.result,
        };
        saveInterviewResults(data).then((res) => {
          if (res.success) {
            this.$message.success("操作成功！");
            this.dialogVisible = false;
            this.$emit("reload");
          }
        });
      } else {
        this.dialogVisible = false;
      }
    },
    getMockStatus() {
      getMockStatus(this.data.id).then((res) => {
        if (res.success) {
          this.mockInfo = res.result;
        }
      });
    },
    handleSuccess(res) {
      this.result = res.result.objectKey;
    },
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isLt10M) {
        this.$message.error("上传文件大小不能超过 10MB!");
      }
      return isLt10M;
    },
    removeFile(file) {
      this.result = "";
    },
    endInterviewEvent() {
      this.$confirm("确认结束面试？", "", {
        center: true,
        type: "info",
      }).then(() => {
        endInterview(this.data.id).then((res) => {
          this.endInterview = true;
          this.$emit("reload");
        });
      });
    },
  },
  mounted() {
    getCourseBanner(9)
      .then((res) => {
        console.log(res);
        if (!res.result.code) {
          this.qrcode = res.result[0];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("changeVisible", val);
      },
    },
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.confirmedTime = false;
        this.endInterview = false;
        this.getMockStatus();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  text-align: center;
}
.title {
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #000000;
  line-height: 24px;
  margin-bottom: 16px;
}
.description {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #757575;
  line-height: 21px;
}
.el-button {
  width: 100px;
}
::v-deep .el-dialog__footer {
  padding: 30px;
}
::v-deep .el-dialog {
  border-radius: 8px;
}
.comment-container {
  width: 400px;
  margin: 0 auto;
  margin-top: 20px;
}
::v-deep .el-rate {
  display: inline-block;
  vertical-align: top;
}
.qrcode {
  width: 108px;
  height: 108px;
  margin-top: 16px;
}
.haventTime {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #0a7aff;
  line-height: 12px;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 32px;
}
.timeZone {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #0a7aff;
  line-height: 12px;
  margin-top: 12px;
}
::v-deep .el-dialog__body {
  padding-top: 0;
  padding-bottom: 0px;
}
.timeRange {
  margin-top: 20px;
}
.pickerRange {
  margin: 12px 0;
}
::v-deep .el-date-editor .el-range-separator {
  width: 30px !important;
}
</style>